import { configureStore, isPlain } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import authReducer from "features/auth/authSlice";
import { apiWithAuth } from "features/auth/auth";
import api from "./api";
import listenerMiddleware from "middleware/listenerMiddleware";

const authMiddleware = (store: any) => (next: any) => (action: any) => {
  if (apiWithAuth.endpoints.login.matchFulfilled(action)) {
    localStorage.setItem("accessToken", action.payload.access);
    localStorage.setItem("refreshToken", action.payload.refresh);
  } else if (apiWithAuth.endpoints.logout.matchFulfilled(action)) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }
  return next(action);
};

function customIsSerializable(value: unknown): boolean {
  if (value instanceof Blob) return true;
  return isPlain(value);
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [api.reducerPath]: api.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable: customIsSerializable,
      },
    }).concat(authMiddleware, listenerMiddleware.middleware, api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
