import Layout from "../components/Layout";
import Login from "../components/Login";
import { Container } from "@mui/material";

export default function LoginPage() {
  return (
    <Layout title="Login" content="Login Page" >
      <Container component="main" maxWidth="xs">
        <Login />
      </Container>
    </Layout>
  );
}
