import { createListenerMiddleware } from "@reduxjs/toolkit";
import { logout } from "features/auth/authSlice";
import { api } from "features/api";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: logout,
  effect: async (_action, listenerApi) => {
    listenerApi.dispatch(api.util.resetApiState());
  },
});

export default listenerMiddleware;
