import { Typography, Box, CircularProgress } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLoginMutation } from "features/auth/auth";
import CustomTextField from "./common/CustomTextField";
import CustomButton from "./common/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomPasswordField from "./common/CustomPasswordField";
import { useEffect } from "react";

type LoginInputs = {
  username: string;
  password: string;
};

export default function Login() {
  const [login, { isLoading, error, isSuccess, data: loginData }] =
    useLoginMutation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>();

  const onSubmit: SubmitHandler<LoginInputs> = (data) => {
    login(data);
  };

  useEffect(() => {
    if (isSuccess && loginData) {
      if (loginData.last_login === null) {
        navigate("/reset-password");
      } else {
        navigate("/");
      }
    }
  }, [isSuccess, loginData, navigate]);

  return (
    <Box
      marginTop={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
      className="rounded-lg bg-white p-6 text-gray-800 "
    >
      <Typography
        component="h1"
        variant="h4"
        sx={{ fontSize: "40px" }}
        className="mb-4  whitespace-nowrap text-[rgb(31,93,107)]"
      >
        CalibrateHCM Sign In{" "}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        marginTop={1}
      >
        <CustomTextField
          id="username"
          label="Username"
          autoComplete="username"
          autoFocus
          error={Boolean(errors.username)}
          helperText={errors.username ? errors.username.message : ""}
          {...register("username", {
            required: "Username is required",
          })}
        />
        <CustomPasswordField
          label="Password"
          id="password"
          autoComplete="current-password"
          error={Boolean(errors.password)}
          helperText={errors.password ? errors.password.message : ""}
          {...register("password", {
            required: "Password is required",
            // minLength: {
            //   value: 6,
            //   message: "Password must be at least 6 characters",
            // },
          })}
        />
        {isLoading ? (
          <div className="mt-4 flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <CustomButton type="submit">Sign In</CustomButton>
        )}

        {error && (
          <Typography
            color="error"
            style={{ marginTop: "16px", textAlign: "center" }}
          >
            Invalid Username or Password. Please try again.
          </Typography>
        )}
      </Box>
    </Box>
  );
}
