import React from "react";
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import { ApiFSItem } from "features/file-system/fileSystem";

interface FileItemProps {
  item: ApiFSItem;
  onClick: () => void;
  onDownloadFile: () => void;
  onDownloadFolder?: () => void;
  className?: string;
  style?: React.CSSProperties;
  isRoot?: boolean;
}

const FileItem: React.FC<FileItemProps> = ({
  item,
  onClick,
  onDownloadFile,
  onDownloadFolder,
  className = "",
  style,
  isRoot,
}) => {
  const handleDownload = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (item.ftype === "folder" && onDownloadFolder) {
      onDownloadFolder();
    } else {
      onDownloadFile();
    }
  };

  return (
    <ListItem
      button
      onClick={onClick}
      disableGutters
      className={`w-full ${className}`}
      style={{ padding: 0, ...style }}
    >
      <ListItemIcon>
        {item.ftype === "folder" ? (
          <FolderIcon sx={{ color: "rgb(31,93,107)" }} />
        ) : (
          <InsertDriveFileIcon sx={{ color: "rgb(31,93,107)" }} />
        )}
      </ListItemIcon>
      <ListItemText primary={item.fname} />
      {(item.ftype !== "folder" || !isRoot) && (
        <IconButton
          onClick={handleDownload}
          className="text-gray-500 hover:text-gray-700"
        >
          <DownloadIcon />
        </IconButton>
      )}
    </ListItem>
  );
};

export default FileItem;
