import React, { useState, useEffect, useCallback } from "react";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import FileList from "./FileList";
import { ArrowBack } from "@mui/icons-material";
import SearchBar from "./SearchBar";
import {
  ApiFileItem,
  ApiFolderItem,
  ApiFSItem,
  useGetFileSystemQuery,
  useLazyDownloadArchiveQuery,
  useLazyDownloadFileQuery,
} from "features/file-system/fileSystem";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import { RootState } from "features/store";
import PaginationBar from "./PaginationBar";

const DEFAULT_LIMIT = 100;

export default function FileSystem() {
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const pathParam = params.get("path");
  const initialPath = pathParam
    ? pathParam.split("/").filter((segment) => segment)
    : [];
  const initialFolderPrefix =
    initialPath.length > 0 ? initialPath.join("/") + "/" : "";

  const [path, setPath] = useState<string[]>(initialPath);
  const [folderPrefix, setFolderPrefix] = useState(initialFolderPrefix);
  const [searchInput, setSearchInput] = useState("");
  const [activeSearch, setActiveSearch] = useState("");
  const [allItems, setAllItems] = useState<ApiFSItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [pageToken, setPageToken] = useState<string | null>(null);
  const effectiveSearch = `${folderPrefix}_${activeSearch}`;
  useEffect(() => {
    setPageToken(null);
  }, [effectiveSearch]);

  const nextPage = useCallback((nextToken: string | null) => {
    setPageToken(nextToken);
  }, []);

  const user = useSelector((state: RootState) => state.auth.user);

  const effectiveLimit = pageToken === null ? DEFAULT_LIMIT * 3 : DEFAULT_LIMIT;
  const { data: currentData, isFetching } = useGetFileSystemQuery(
    {
      folderPrefix,
      pageToken,
      search: activeSearch.trim() || null,
      limit: effectiveLimit,
    },
    { refetchOnMountOrArgChange: true },
  );

  const [triggerDownloadFile] = useLazyDownloadFileQuery();
  const [triggerDownloadArchive] = useLazyDownloadArchiveQuery();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pathParam = params.get("path");
    const newPath = pathParam
      ? pathParam.split("/").filter((segment) => segment)
      : [];
    const newFolderPrefix = newPath.length > 0 ? newPath.join("/") + "/" : "";
    if (newFolderPrefix !== folderPrefix) {
      setPath(newPath);
      setFolderPrefix(newFolderPrefix);
      setSearchInput("");
      setActiveSearch("");
      setAllItems([]);
      setCurrentPage(1);
    }
  }, [location.search, folderPrefix]);

  useEffect(() => {
    setAllItems([]);
    setCurrentPage(1);
  }, [activeSearch, folderPrefix]);

  useEffect(() => {
    if (isFetching) return;
    if (currentData) {
      if (pageToken === null) {
        setAllItems(currentData.folders);
      } else {
        setAllItems((prevItems) => [...prevItems, ...currentData.folders]);
      }
    }
  }, [currentData, pageToken, isFetching]);

  const totalPages = Math.ceil(allItems.length / DEFAULT_LIMIT) || 1;

  useEffect(() => {
    const isLastPageIncomplete =
      allItems.length > 0 && allItems.length % DEFAULT_LIMIT !== 0;

    const shouldPrefetch =
      !isFetching && 
      pageToken === null && 
      currentData?.next_token && 
      totalPages > 1 && 
      currentPage === totalPages - 1 &&
      isLastPageIncomplete;
    if (shouldPrefetch) {
      nextPage(currentData.next_token);
    }
  }, [
    currentPage,
    totalPages,
    allItems.length,
    currentData,
    isFetching,
    pageToken,
    nextPage,
  ]);

  const displayedItems = allItems.slice(
    (currentPage - 1) * DEFAULT_LIMIT,
    currentPage * DEFAULT_LIMIT,
  );

  const handleEnterFolder = (folderName: string) => {
    const newPath = [...path, folderName];
    setPath(newPath);
    setFolderPrefix(newPath.join("/") + "/");
    navigate(`?path=${newPath.join("/")}`);
    setSearchInput("");
    setActiveSearch("");
    setAllItems([]);
    setCurrentPage(1);
  };

  const handleGoBack = () => {
    const newPath = path.slice(0, -1);
    setPath(newPath);
    setFolderPrefix(newPath.length > 0 ? newPath.join("/") + "/" : "");
    navigate(`?path=${newPath.join("/")}`);
    setSearchInput("");
    setActiveSearch("");
    setAllItems([]);
    setCurrentPage(1);
  };

  const handleDownloadFile = async (file: ApiFileItem) => {
    if (file.ftype === "folder") return;
    const folderPath = path.length > 0 ? path.join("/") + "/" : "";
    const fullFilePath = folderPath + file.fname;
    try {
      const { file_urls } = await triggerDownloadFile({
        source: fullFilePath,
        fileName: file.fname,
      }).unwrap();
      if (file_urls.length > 0) {
        const link = document.createElement("a");
        link.href = file_urls[0];
        link.download = file.fname;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error("No file URL returned");
      }
    } catch (err) {
      console.error("Error downloading file", err);
    }
  };

  const handleDownloadFolder = async () => {
    const folderPath = path.length > 0 ? path.join("/") + "/" : "";
    try {
      const { file_urls } = await triggerDownloadFile({
        source: folderPath,
        fileName: "",
      }).unwrap();
      if (file_urls.length > 0) {
        file_urls.forEach((url, index) => {
          setTimeout(() => {
            const fileName = `file-${index + 1}`;
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();
          }, index * 700);
        });
      } else {
        console.error("No file URLs returned for folder");
      }
    } catch (err) {
      console.error("Error downloading folder", err);
    }
  };

  const handleDownloadArchive = async (folder: ApiFolderItem) => {
    const folderPath =
      (path.length > 0 ? path.join("/") + "/" : "") + folder.fname + "/";
    const archiveName = folder.fname;
    try {
      const blob = await triggerDownloadArchive({
        source: folderPath,
        fileName: archiveName,
      }).unwrap();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${archiveName}.zip`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Error downloading folder archive", err);
    }
  };

  const displayPath = path.length > 0 ? `/${path.join("/")}` : "";

  const handleSearchClick = () => {
    setPageToken(null); 
    setActiveSearch(searchInput);
    setAllItems([]);
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setSearchInput("");
    setActiveSearch("");
    setAllItems([]);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (
      displayedItems.length < DEFAULT_LIMIT &&
      currentData?.next_token
    ) {
      nextPage(currentData.next_token);
    } else if (currentData?.next_token) {
      nextPage(currentData.next_token);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const canGoBack = currentPage > 1;
  const canGoForward =
    currentPage < totalPages || Boolean(currentData?.next_token);

  return (
    <Box p={2}>
      <div className="mb-5 mt-6">
        <Typography
          component="h1"
          variant="h4"
          className="text-center text-[rgb(31,93,107)]"
        >
          {user?.username} File System
        </Typography>
      </div>

      <SearchBar
        searchQuery={searchInput}
        onSearchChange={setSearchInput}
        onSearchClick={handleSearchClick}
        onClear={handleClearSearch}
      />

      <div className="mt-3 flex h-10 items-center">
        {path.length > 0 && (
          <IconButton onClick={handleGoBack}>
            <ArrowBack className="text-[rgb(31,93,107)]" />
          </IconButton>
        )}
        <div className="flex-1">
          <Typography
            component="h1"
            variant="h5"
            className="font-medium text-[rgb(31,93,107)]"
          >
            {displayPath}
          </Typography>
        </div>
        {displayedItems.filter((item) => item.ftype !== "folder").length >
          0 && (
          <IconButton onClick={handleDownloadFolder} className="mr-2">
            <DownloadIcon className="text-[rgb(31,93,107)]" />
          </IconButton>
        )}
      </div>

      {isFetching ? (
        <div className="mt-4 flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <Box className="mb-10">
          {displayedItems.length === 0 ? (
            <Typography variant="h6" align="center" color="textSecondary">
              No files or folders
            </Typography>
          ) : (
            <>
              <FileList
                items={displayedItems}
                onEnterFolder={handleEnterFolder}
                onDownloadFile={handleDownloadFile}
                onDownloadFolder={handleDownloadArchive}
                isRoot={path.length === 0}
              />
              {allItems.length >= DEFAULT_LIMIT && (
                <PaginationBar
                  canGoBack={canGoBack}
                  canGoForward={canGoForward}
                  onPrev={handlePrevPage}
                  onNext={handleNextPage}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageSelect={(page) => setCurrentPage(page)}
                />
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
