import Box from "@mui/system/Box";
import { Typography, Link } from "@mui/material";
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "config";

export default function Footer() {
  return (
    <footer className="flex flex-col items-center bg-black py-8 text-white">
      <Typography variant="body2" className="mb-6 text-gray-400">
        © {new Date().getFullYear()} All rights reserved.
      </Typography>
      <div className="mt-4 flex space-x-6">
        <Link
          href={PRIVACY_POLICY_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          PRIVACY POLICY
        </Link>
        <Link
          href={TERMS_OF_SERVICE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          TERMS AND CONDITIONS
        </Link>
      </div>
    </footer>
  );
}
