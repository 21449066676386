import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "features/store";

type ProtectedRouteProps = {
  children: JSX.Element;
};

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isAuthenticated, lastLogin } = useAppSelector((state) => state.auth);

  if (isAuthenticated && typeof lastLogin == "boolean" && !lastLogin) {
    return <Navigate to="/reset-password" replace />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
