import React from "react";
import { Box, Typography } from "@mui/material";
import CustomButton from "components/common/CustomButton";

interface PaginationBarProps {
  canGoBack: boolean;
  canGoForward: boolean;
  onPrev: () => void;
  onNext: () => void;
  currentPage: number;
  totalPages?: number | null;
  onPageSelect: (page: number) => void;
}

export default function PaginationBar({
  canGoBack,
  canGoForward,
  onPrev,
  onNext,
  currentPage,
  totalPages,
  onPageSelect,
}: PaginationBarProps) {
  const knownTotal = typeof totalPages === "number";

  const numberButtonStyle = {
    minWidth: "24px",
    padding: "2px 4px",
    fontSize: "0.75rem",
    lineHeight: 1,
    minHeight: 0,
    border: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
    color: "inherit",
    cursor: "pointer",
  };

  const activeButtonStyle = {
    ...numberButtonStyle,
    backgroundColor: "rgb(31,93,107)",
    color: "#fff",
  };

  let pageWindow: number[] = [];

  if (knownTotal) {
    if (totalPages! <= 3) {
      pageWindow = Array.from({ length: totalPages! }, (_, i) => i + 1);
    } else {
      if (currentPage <= 2) {
        pageWindow = [1, 2, 3];
      } else if (currentPage >= totalPages! - 1) {
        pageWindow = [totalPages! - 2, totalPages! - 1, totalPages!];
      } else {
        pageWindow = [currentPage - 1, currentPage, currentPage + 1];
      }
    }
  } else {
    if (currentPage <= 2) {
      pageWindow = [1, 2, 3];
    } else {
      pageWindow = [currentPage - 1, currentPage, currentPage + 1];
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={0.5}
      mt={1}
    >
      {canGoBack && (
        <CustomButton
          fullWidth={false}
          style={{
            minWidth: "40px",
            padding: "2px 6px",
            fontSize: "0.75rem",
            lineHeight: 1,
            minHeight: 0,
            cursor: "pointer",
          }}
          onClick={onPrev}
        >
          Prev
        </CustomButton>
      )}

      {/* If the first page in the window is not the very first page, show first page button and ellipsis */}
      {pageWindow[0] > 1 && (
        <>
          <CustomButton
            fullWidth={false}
            style={pageWindow[0] === 1 ? activeButtonStyle : numberButtonStyle}
            onClick={() => onPageSelect(1)}
          >
            1
          </CustomButton>
          {pageWindow[0] > 2 && (
            <Typography variant="body2" sx={{ lineHeight: 1 }}>
              ...
            </Typography>
          )}
        </>
      )}

      {pageWindow.map((pageNumber) => (
        <CustomButton
          key={pageNumber}
          fullWidth={false}
          style={
            pageNumber === currentPage ? activeButtonStyle : numberButtonStyle
          }
          onClick={() => onPageSelect(pageNumber)}
        >
          {pageNumber}
        </CustomButton>
      ))}


      {/* For unknown total pages, just show an ellipsis if more pages can be fetched */}
      {canGoForward && (
        <Typography variant="body2" sx={{ lineHeight: 1 }}>
          ...
        </Typography>
      )}

      {canGoForward && (
        <CustomButton
          fullWidth={false}
          style={{
            minWidth: "40px",
            padding: "2px 6px",
            fontSize: "0.75rem",
            lineHeight: 1,
            minHeight: 0,
            cursor: "pointer",
          }}
          onClick={onNext}
        >
          Next
        </CustomButton>
      )}
    </Box>
  );
}
