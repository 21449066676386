import React from "react";
import { List } from "@mui/material";
import FileItem from "./FileItem";
import { ApiFSItem } from "features/file-system/fileSystem";

interface FileListProps {
  items: ApiFSItem[];
  onEnterFolder: (folderName: string) => void;
  onDownloadFile: (item: ApiFSItem) => void;
  onDownloadFolder: (folder: any) => void;
  isRoot: boolean;
}

const FileList: React.FC<FileListProps> = ({
  items,
  onEnterFolder,
  onDownloadFile,
  onDownloadFolder,
  isRoot,
}) => {
  const sortedItems = [
    ...items.filter((item) => item.ftype === "folder"),
    ...items.filter((item) => item.ftype !== "folder"),
  ];

  return (
    <List className="w-full">
      {sortedItems.map((item, index) => (
        <FileItem
          key={`${item.fname}-${index}`}
          item={item}
          onClick={() => {
            if (item.ftype === "folder") {
              onEnterFolder(item.fname);
            }
          }}
          onDownloadFile={() => onDownloadFile(item)}
          onDownloadFolder={() => onDownloadFolder(item)}
          isRoot={isRoot}
          style={{
            backgroundColor: index % 2 === 0 ? "rgba(55,191,190,0.1)" : "white",
          }}
        />
      ))}
    </List>
  );
};

export default FileList;
