import React from "react";
import {
  TextField,
  IconButton,
  Stack,
  Box,
  InputAdornment,
} from "@mui/material";
import CustomButton from "components/common/CustomButton";
import ClearIcon from "@mui/icons-material/Clear";

interface SearchBarProps {
  searchQuery: string;
  onSearchChange: (value: string) => void;
  onSearchClick: () => void;
  onClear: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchQuery,
  onSearchChange,
  onSearchClick,
  onClear,
}) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search files and folders..."
        value={searchQuery}
        onChange={(e) => onSearchChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearchClick();
          }
        }}
        InputProps={{
          endAdornment: searchQuery && (
            <InputAdornment position="end">
              <IconButton onClick={onClear} edge="end" sx={{ mr: 0.2 }}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "40px",
            boxSizing: "border-box",
            padding: 0,
          },
          "& .MuiOutlinedInput-input": {
            padding: "0 12px",
            lineHeight: "normal",
            boxSizing: "border-box",
          },
        }}
      />
      <Box>
        <CustomButton
          onClick={onSearchClick}
          sx={{
            height: "40px",
            minWidth: "80px",
            padding: "0 12px",
            borderRadius: 0,
            boxSizing: "border-box",
            lineHeight: "normal",
          }}
        >
          Search
        </CustomButton>{" "}
      </Box>
    </Stack>
  );
};

export default SearchBar;
