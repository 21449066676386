import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiWithAuth, LoginResponse } from "./auth";

export interface AuthState {
  isAuthenticated?: boolean;
  user?: any;
  accessToken?: string | null;
  refreshToken?: string | null;
  lastLogin?: boolean;
}

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem("accessToken"),
  user: null,
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    tokenRefreshed: (
      state,
      action: PayloadAction<{ access: string; refresh: string }>,
    ) => {
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        apiWithAuth.endpoints.login.matchFulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          state.isAuthenticated = true;
          state.accessToken = action.payload.access;
          state.refreshToken = action.payload.refresh;
          state.lastLogin = action.payload.last_login ? true : false;
        },
      )
      .addMatcher(apiWithAuth.endpoints.checkAuth.matchFulfilled, (state) => {
        state.isAuthenticated = true;
      })
      .addMatcher(
        apiWithAuth.endpoints.resetPassword.matchFulfilled,
        (state) => {
          state.lastLogin = true;
        },
      )
      .addMatcher(apiWithAuth.endpoints.checkAuth.matchRejected, (state) => {
        state.isAuthenticated = false;
        state.accessToken = null;
      })
      .addMatcher(apiWithAuth.endpoints.logout.matchFulfilled, (state) => {
        state.isAuthenticated = false;
        state.user = null;
        state.accessToken = null;
        state.refreshToken = null;
      })
      .addMatcher(
        apiWithAuth.endpoints.getUser.matchFulfilled,
        (state, action) => {
          state.user = action.payload;
        },
      );
  },
});

export const { tokenRefreshed, logout } = authSlice.actions;
export default authSlice.reducer;
