import { useGetUserQuery } from "features/auth/auth";
import Layout from "../components/Layout";
import FileSystem from "../components/file-system/FileSystem";
import { CircularProgress, Container } from "@mui/material";

export default function FileSystemPage() {
  const { isLoading: userLoading } = useGetUserQuery(undefined, {});

  if (userLoading) {
    return (
      <>
        <div
          style={{
            height: "100vh",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      </>
    );
  }

  return (
    <Layout title="File System" content="File System">
      <Container component="main" maxWidth="lg">
        <FileSystem />
      </Container>
    </Layout>
  );
}
