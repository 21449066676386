import { API_URL } from "config";
import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
} from "@reduxjs/toolkit/query";
import { RootState } from "./store";
import { logout, tokenRefreshed } from "features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.auth.accessToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
      const refreshResult = await baseQuery(
        {
          url: `/auth/token/refresh/`,
          method: "POST",
          body: { refresh: refreshToken },
        },
        api,
        extraOptions,
      );
      if (refreshResult.data) {
        const { access, refresh } = refreshResult.data as {
          access: string;
          refresh: string;
        };
        localStorage.setItem("accessToken", access);
        api.dispatch(tokenRefreshed({ access, refresh }));
        if (typeof args != "string" && args.url == "/auth/token/verify/") {
          args.body = { token: access };
        }
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logout());
      }
    } else {
     return result
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Auth", "FileSystem"],
  endpoints: () => ({}),
});

export default api;
