import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./containers/LoginPage";
import { CircularProgress, Container, CssBaseline } from "@mui/material";
import { useCheckAuthMutation, useGetUserQuery } from "features/auth/auth";
import { useEffect, useState } from "react";
import ResetPasswordPage from "./containers/ResetPasswordPage";
import FileSystemPage from "containers/FileSystemPage";
import ProtectedRoute from "components/common/ProtectedRoute";
import PublicRoute from "components/common/PublicRoute";

function App() {
  const [checkAuth] = useCheckAuthMutation();
  const [authInProgress, setAuthInProgress] = useState(true);

  useEffect(() => {
    async function performAuthCheck() {
      try {
        await checkAuth().unwrap();
      } catch (error) {
        console.error("Authentication check failed:", error);
      } finally {
        setAuthInProgress(false);
      }
    }
    performAuthCheck();
  }, [checkAuth]);

  return (
    <>
      <CssBaseline />
      <div style={{ backgroundColor: "white" }}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <LoginPage />
                </PublicRoute>
              }
            />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <FileSystemPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
