import { createTheme } from "@mui/material/styles";

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

import { LinkProps } from "@mui/material/Link";
import React from "react";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const theme = createTheme({
  typography: {
    fontFamily: "Lato, Arial, sans-serif",
    h4: {
      fontSize: "44px",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          fontFamily: "Lato, Arial, sans-serif",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          color: "rgb(31, 93, 107)",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: "white",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "none",
            color: "white",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Century Gothic, sans-serif",
        },
      },
    },
  },
});
