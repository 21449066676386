import { TextField, TextFieldProps } from "@mui/material";
import { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

export interface CustomTextFieldProps extends Omit<TextFieldProps, "ref"> {
  name?: string;
  register?: UseFormRegisterReturn;
}

const CustomTextField = forwardRef<HTMLInputElement, CustomTextFieldProps>(
  ({ label, register, name, ...props }, ref) => {
    return (
      <TextField
        margin="normal"
        required
        fullWidth
        label={label}
        inputRef={register ? register.ref : ref}
        name={name}
        sx={{
          "& .MuiInputLabel-root": {
            color: "#333",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#333",
          },
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgb(31, 93, 107)",
            },
          },
          "& .MuiInputBase-root:hover": {
            borderColor: "rgb(31, 93, 107)",
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(31, 93, 107)",
          },
          
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "rgb(31, 93, 107)",
            },
        }}
        {...props}
      />
    );
  },
);

export default CustomTextField;
