import { FetchArgs } from "@reduxjs/toolkit/query";
import api from "../api";

export interface ApiFSResponse {
  next_token: string | null;
  folders: ApiFSItem[];
}

export type ApiFSItem = ApiFolderItem | ApiFileItem;

export interface ApiFolderItem {
  fname: string;
  ftype: "folder";
}

export interface ApiFileItem {
  fname: string;
  ftype: string;
}

interface DownloadResponse {
  next_token: string | null;
  file_urls: string[];
}

export interface DownloadFileArgs {
  source: string;
  fileName: string;
}

export const fileSystemApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFileSystem: builder.query<
      ApiFSResponse,
      {
        folderPrefix: string;
        pageToken: string | null;
        search: string | null;
        limit: number | null;
      }
    >({
      query: ({ folderPrefix, pageToken, search, limit }) => {
        const cleanedSuffix = folderPrefix.replace(/^\/+/, "");
        return {
          url: `/web/folders/full-search/`,
          params: {
            prefix: `OneDrive/data${cleanedSuffix ? "/" + cleanedSuffix : "/"}`,
            search_name: search || undefined,
            next_token: pageToken || undefined,
            limit: limit || 100,
          },
        };
      },
      providesTags: ["FileSystem"],
    }),

    downloadFile: builder.query<DownloadResponse, DownloadFileArgs>({
      query: ({ source }): FetchArgs => ({
        url: `/web/files/download_url/`,
        method: "GET",
        params: {
          fname: `OneDrive/data/${source}`,
        },
        responseHandler: (response: Response) => response.json(),
      }),
    }),

    downloadArchive: builder.query<Blob, DownloadFileArgs>({
      query: ({ source }): FetchArgs => ({
        url: `/web/files/download/`,
        method: "GET",
        params: {
          source: `OneDrive/data/${source}`,
        },
        responseHandler: async (response: Response) => {
          const base64String = await response.text();
          const byteCharacters = atob(base64String);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          return new Blob([byteArray]);
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFileSystemQuery,
  useLazyDownloadFileQuery,
  useLazyDownloadArchiveQuery,
} = fileSystemApi;
