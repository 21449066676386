import { Button, ButtonProps } from "@mui/material";

interface CustomButtonProps extends ButtonProps {
  children: React.ReactNode; 
}

const CustomButton = ({ children, fullWidth = true, ...props }: CustomButtonProps) => {
  return (
    <Button
      {...props}
      fullWidth={fullWidth}
      variant="outlined"
      sx={{
        height: "40px",
        minWidth: "80px",
        padding: "0 12px",
        borderColor: "rgb(31, 93, 107)",
        color: "rgb(31, 93, 107)",
        fontWeight: "bold",
        "&:hover": {
          backgroundColor: "rgb(31, 93, 107)",
          color: "white",
          borderColor: "rgb(31, 93, 107)",
        },
      }}
      className="rounded-md bg-[rgb(31,93,107)] px-4 py-2 text-white hover:bg-[rgb(31,93,107)]"
    >
      {children}
    </Button>
  );
};

export default CustomButton;
