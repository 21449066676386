import ResetPassword from "../components/ResetPassword";
import Layout from "../components/Layout";
import { Container } from "@mui/material";

export default function ResetPasswordPage() {
  return (
    <Layout title="Reset Password" content="Reset Password Page">
      <Container component="main" maxWidth="xs">
        <ResetPassword />
      </Container>
    </Layout>
  );
}
