import { useState, forwardRef } from "react";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomTextField, { CustomTextFieldProps } from "./CustomTextField";

const CustomPasswordField = forwardRef<HTMLInputElement, CustomTextFieldProps>(
  ({ label, register, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <CustomTextField
        type={showPassword ? "text" : "password"}
        label={label}
        inputRef={register ? register.ref : ref}
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          ...(props.InputProps || {}),
        }}
      />
    );
  }
);

export default CustomPasswordField;
