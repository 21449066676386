import { Typography, Box, CircularProgress } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { useResetPasswordMutation } from "features/auth/auth";
import CustomButton from "./common/CustomButton";
import CustomPasswordField from "./common/CustomPasswordField";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

type ResetPasswordInputs = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export default function ResetPassword() {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const navigate = useNavigate();
  const [serverError, setServerError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ResetPasswordInputs>();

  const onSubmit: SubmitHandler<ResetPasswordInputs> = async (data) => {
    setServerError(null);
    try {
      await resetPassword({
        old_password: data.oldPassword,
        new_password: data.newPassword,
      })
        .unwrap()
        .then(() => {
          navigate("/");
        })
        .catch((error) => {
          const errorMessage = error?.data?.message || "Something went wrong";

          setServerError(errorMessage);
          console.error("Login failed:", error);
        });
    } catch (error) {
      console.error("Failed to change password:", error);
    }
  };

  return (
    <Box
      marginTop={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
      className="rounded-lg bg-white p-6 text-gray-800 "
    >
      <Typography
        component="h1"
        variant="h4"
        className="mb-4  text-[rgb(31,93,107)]"
      >
        Reset password{" "}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        marginTop={1}
      >
        <CustomPasswordField
          id="oldPassword"
          label="Old password"
          autoComplete="current-password"
          error={Boolean(errors.oldPassword)}
          helperText={errors.oldPassword ? errors.oldPassword.message : ""}
          {...register("oldPassword", { required: "Old password is required" })}
        />
        <CustomPasswordField
          label="New password"
          id="newPassword"
          autoComplete="newPassword"
          error={Boolean(errors.newPassword)}
          helperText={errors.newPassword ? errors.newPassword.message : ""}
          {...register("newPassword", {
            required: "New password is required",
            minLength: {
              value: 8,
              message: "New password must be at least 8 characters",
            },
            validate: {
              hasUpperCase: (value) =>
                /[A-Z]/.test(value) ||
                "Password must contain at least one uppercase letter",
              hasNumber: (value) =>
                /\d/.test(value) || "Password must contain at least one number",
            },
          })}
        />

        <CustomPasswordField
          id="confirmNewPassword"
          label="Confirm new password"
          autoComplete="new-password"
          error={Boolean(errors.confirmNewPassword)}
          helperText={
            errors.confirmNewPassword ? errors.confirmNewPassword.message : ""
          }
          {...register("confirmNewPassword", {
            required: "Please confirm your new password",
            validate: (value) =>
              value === getValues("newPassword") || "Passwords do not match",
          })}
        />
        {isLoading ? (
          <div className="mt-4 flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <CustomButton type="submit">Confirm</CustomButton>
        )}

        {serverError && (
          <Typography
            color="error"
            style={{ marginTop: "16px", textAlign: "center" }}
          >
            {serverError}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
